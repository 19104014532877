<template>
  <div class="contactDetailsView__container">
    <div v-for="item in contactInfo" :key="item.content" class="contactDetailsView__content">
      <component :is="item.icon" />
      <base-link :label="item.content" @click="navigateTo(item.href)"/>
    </div>
  </div>
</template>

<script setup>
import EmailIcon from "../components/icons/EmailIcon.vue";
import GithubIcon from "../components/icons/GithubIcon.vue";
import LinkedinIcon from "../components/icons/LinkedinIcon.vue";
import BaseLink from "@/components/BaseLink.vue";

const contactInfo = [
  {
    content: "jacob@jacobli.me",
    href: "mailto:jacob@jacobli.me",
    type: "Email",
    icon: EmailIcon
  },
  {
    content: "github.com/jacobbli",
    href: "https://github.com/jacobbli",
    type: "Github",
    icon: GithubIcon
  },
  {
    content: "linkedin.com/in/jacobbli",
    href: "https://linkedin.com/in/jacobbli/",
    type: "LinkedIn",
    icon: LinkedinIcon
  }
]

function navigateTo(target) {
  window.open(target)
}
</script>

<style lang="scss" scoped>
.contactDetailsView__container {
  display: flex;
  flex-direction: column;

  gap: 20px;

  .contactDetailsView__content {
    display: flex;
    gap: 8px;
  }
}
</style>
